const binding = (code, dna = true) => {
  const value = NitrogenousBase[code]

  return !dna && value === 'T' ? 'U' : value
}

const NitrogenousBase = {
  C: 'G',
  G: 'C',
  T: 'A',
  U: 'A',
  A: 'T',
}

export default binding
