import { Box, Heading } from '@chakra-ui/react'

const Base = ({ code, onClick }) => {
  return (
    <Box
      w={20}
      bg={color[code]}
      textAlign="center"
      onClick={() => onClick(code)}
    >
      <Heading>{code}</Heading>
    </Box>
  )
}

const color = {
  A: 'blue.300',
  C: 'purple.400',
  T: 'orange',
  G: 'green.300',
  U: 'red.200',
}

export default Base
