import { useState } from 'react'

import { Box, Flex, Heading, Button } from '@chakra-ui/react'
import Base from './components/Base'
import Protein from './components/Protein'

import binding from './utils/binding'


function App() {
  const [dna, setDNA] = useState([])
  const [transcription, setTranscription] = useState(false)
  const [translation, setTranslation] = useState(false)

  function addBase(base) {
    setDNA([...dna, base])
  }

  return (
    <Box bg="gray.100" minH="100vh" padding={{'lg': 10}}>
      <Box visibility={transcription ? 'hidden' : 'visible'}>
        <Heading fontSize="xl">DNA에 염기 추가하기:</Heading>
        <Flex>
          <Base code="A" onClick={addBase} />
          <Base code="T" onClick={addBase} />
          <Base code="G" onClick={addBase} />
          <Base code="C" onClick={addBase} />
        </Flex>
      </Box>

      <Heading fontSize="2xl" paddingTop={10}>
        DNA:
      </Heading>
      {dna.length >= 1 && (
          <Heading fontSize="lg" marginTop={2}>
            (주형가닥)
          </Heading>
        )}
      <Flex>
        {dna.map((code, index) => (
          <Base key={index} code={code} />
        ))}
        
      </Flex>
      <Flex marginTop={1}>
        {dna.map((code, index) => (
          <Base key={index} code={binding(code)} />
        ))}
      </Flex>

      {transcription ? (
        <>
          <Heading fontSize="2xl" paddingTop={10}>
            RNA:
          </Heading>
          <Flex marginTop={1}>
            {dna.map((code, index) => (
              <Base key={index} code={binding(code, false)} />
            ))}
          </Flex>
          {translation ? (
            <>
              <Heading fontSize="2xl" paddingTop={10}>
                단백질:
              </Heading>
              <Flex marginTop={1}>
                {dna.map(e => binding(e, false)).reduce((r, e, i) =>
                      (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r
                  , []).map((code, index) => (
                  <Protein key={index} code={code.join('')} />
                ))}
              </Flex>
            </>
            ) : (
            <Button
              marginTop={5}
              bgColor="pink.100"
              onClick={() => setTranslation(true)}
            >
              번역
            </Button>
          )}
        </>
      ) : (
        <Button
          marginTop={5}
          bgColor="pink.100"
          onClick={() => setTranscription(true)}
        >
          전사
        </Button>
      )}
    </Box>
  )
}

export default App
