import { Box, Heading, Text } from '@chakra-ui/react'
import protein from '../utils/protein'

const Protein = ({ code, onClick }) => {
  if(!protein(code)) return
  return (
    <Box
      minW={20}
      padding={2}
      bg={randomHSL()}
      textAlign="center"
      onClick={() => onClick(code)}
    >
      <Text>{code}</Text>
      <Heading fontSize='3xl'>{protein(code)}</Heading>
    </Box>
  )
}


const randomHSL = () => `hsla(${~~(360 * Math.random())}, 70%, 80%, 1)`

export default Protein
