const Codon = [
  { codon: 'UUU', protein: '페닐알라닌' },
  { codon: 'UCU', protein: '세린' },
  { codon: 'UAU', protein: '티로신' },
  { codon: 'UGU', protein: '시스테인' },
  { codon: 'UUC', protein: '페닐알라닌' },
  { codon: 'UCC', protein: '세린' },
  { codon: 'UAC', protein: '티로신' },
  { codon: 'UGC', protein: '시스테인' },
  { codon: 'UUA', protein: '류신' },
  { codon: 'UCA', protein: '세린' },
  { codon: 'UAA', protein: '종결' },
  { codon: 'UGA', protein: '종결' },
  { codon: 'UUG', protein: '류신' },
  { codon: 'UCG', protein: '세린' },
  { codon: 'UAG', protein: '종결' },
  { codon: 'UGG', protein: '트립토판' },
  { codon: 'CUU', protein: '류신' },
  { codon: 'CCU', protein: '프롤린' },
  { codon: 'CAU', protein: '히스티딘' },
  { codon: 'CGU', protein: '아르기닌' },
  { codon: 'CUC', protein: '류신' },
  { codon: 'CCC', protein: '프롤린' },
  { codon: 'CAC', protein: '히스티딘' },
  { codon: 'CGC', protein: '아르기닌' },
  { codon: 'CUA', protein: '류신' },
  { codon: 'CCA', protein: '프롤린' },
  { codon: 'CAA', protein: '글루타민' },
  { codon: 'CGA', protein: '아르기닌' },
  { codon: 'CUG', protein: '류신' },
  { codon: 'CCG', protein: '프롤린' },
  { codon: 'CAG', protein: '글루타민' },
  { codon: 'CGG', protein: '아르기닌' },
  { codon: 'AUU', protein: '이소류신' },
  { codon: 'ACU', protein: '트레오닌' },
  { codon: 'AAU', protein: '아스파라긴' },
  { codon: 'AGU', protein: '세린' },
  { codon: 'AUC', protein: '이소류신' },
  { codon: 'ACC', protein: '트레오닌' },
  { codon: 'AAC', protein: '아스파라긴' },
  { codon: 'AGC', protein: '세린' },
  { codon: 'AUA', protein: '이소류신' },
  { codon: 'ACA', protein: '트레오닌' },
  { codon: 'AAA', protein: '리신' },
  { codon: 'AGA', protein: '아르기닌' },
  { codon: 'AUG', protein: '메티오닌' },
  { codon: 'ACG', protein: '트레오닌' },
  { codon: 'AAG', protein: '리신' },
  { codon: 'AGG', protein: '아르기닌' },
  { codon: 'GUU', protein: '발린' },
  { codon: 'GCU', protein: '알라닌' },
  { codon: 'GAU', protein: '아스파라긴산' },
  { codon: 'GGU', protein: '글리신' },
  { codon: 'GUC', protein: '발린' },
  { codon: 'GCC', protein: '알라닌' },
  { codon: 'GAC', protein: '아스파라긴산' },
  { codon: 'GGC', protein: '글리신' },
  { codon: 'GUA', protein: '발린' },
  { codon: 'GCA', protein: '알라닌' },
  { codon: 'GAA', protein: '글루타민산' },
  { codon: 'GGA', protein: '글리신' },
  { codon: 'GUG', protein: '발린' },
  { codon: 'GCG', protein: '알라닌' },
  { codon: 'GAG', protein: '글루타민산' },
  { codon: 'GGG', protein: '글리신' },
]

export default Codon